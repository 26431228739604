import {
  OrderingType,
  PaymentFormQuery,
  PaymentProcessorType,
  VenueFieldRequirement,
} from 'lib/gql'
import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

import {
  PaymentMethodKey,
  PaymentMethodValue,
} from './hooks/usePaymentFormMethods'
import { paymentMethodSchema } from './PaymentMethodSchema'

export function getPaymentSchema(
  {
    orderingType,
    hasOptions,
    emailFieldRequirement,
    coverCountFieldRequirement,
    paymentFormData,
    showEmailFieldWhenDineIn,
    isEonxEnv,
  }: {
    paymentFormData?: PaymentFormQuery
    hasOptions: boolean
    orderingType: OrderingType | null
    emailFieldRequirement: VenueFieldRequirement
    coverCountFieldRequirement: VenueFieldRequirement
    showEmailFieldWhenDineIn: boolean
    isEonxEnv: boolean
  },
  { formatMessage }: IntlShape,
) {
  return Yup.object()
    .shape({
      // customer details
      name: Yup.string()
        .trim()
        .required(
          formatMessage({
            defaultMessage: 'Your name is required',
            id: '1KxtyA',
          }),
        ),
      email: isEonxEnv
        ? Yup.string().nullable()
        : Yup.string()
            .trim()
            .when(
              [
                'paymentMethod.key',
                'paymentMethod.processorType',
                'marketingUserOptIn',
                'marketingUserOptInVisible',
              ],
              {
                is: (paymentMethodKey, processorType, optIn, optInVisible) =>
                  processorType === PaymentProcessorType.Afterpay ||
                  paymentMethodKey === PaymentMethodKey.TabNew ||
                  (optIn && optInVisible),
                then: Yup.string()
                  .email(
                    formatMessage({
                      id: 'qDmele',
                      defaultMessage: 'Email must be valid',
                    }),
                  )
                  .nullable()
                  .required(
                    formatMessage({
                      id: 'gtleS5',
                      defaultMessage: 'Please provide your email address',
                    }),
                  ),
                otherwise:
                  emailFieldRequirement === VenueFieldRequirement.Required
                    ? Yup.string()
                        .email(
                          formatMessage({
                            id: 'qDmele',
                            defaultMessage: 'Email must be valid',
                          }),
                        )
                        .nullable()
                        .required(
                          formatMessage({
                            id: 'gtleS5',
                            defaultMessage: 'Please provide your email address',
                          }),
                        )
                    : emailFieldRequirement === VenueFieldRequirement.Hidden &&
                        !showEmailFieldWhenDineIn
                      ? Yup.string()
                          .email(
                            formatMessage({
                              id: 'qDmele',
                              defaultMessage: 'Email must be valid',
                            }),
                          )
                          .nullable()
                          .strip(true)
                      : Yup.string()
                          .email(
                            formatMessage({
                              id: 'qDmele',
                              defaultMessage: 'Email must be valid',
                            }),
                          )
                          .nullable(),
              },
            ),
      coverCount:
        coverCountFieldRequirement === VenueFieldRequirement.Required
          ? Yup.number()
              .typeError(
                formatMessage({
                  id: 'mG+rlo',
                  defaultMessage: 'Cover count is required',
                }),
              )
              .integer()
              .min(
                1,
                formatMessage({
                  id: 'GaaUEa',
                  defaultMessage: 'Requires a number between 1 and 20',
                }),
              )
              .max(
                20,
                formatMessage({
                  id: 'GaaUEa',
                  defaultMessage: 'Requires a number between 1 and 20',
                }),
              )
              .required(
                formatMessage({
                  defaultMessage: 'Cover count is required',
                  id: 'mG+rlo',
                }),
              )
          : coverCountFieldRequirement === VenueFieldRequirement.Hidden
            ? Yup.number().nullable().strip(true)
            : Yup.number()
                .typeError(
                  formatMessage({
                    id: 'EKawKX',
                    defaultMessage: 'Cover count must be a number',
                  }),
                )
                .transform((value) => (isNaN(value) ? null : value))
                .integer()
                .min(
                  1,
                  formatMessage({
                    id: 'GaaUEa',
                    defaultMessage: 'Requires a number between 1 and 20',
                  }),
                )
                .max(
                  20,
                  formatMessage({
                    id: 'GaaUEa',
                    defaultMessage: 'Requires a number between 1 and 20',
                  }),
                )
                .nullable(),
      roomNumber: Yup.string().when('paymentMethod.processorType', {
        is: PaymentProcessorType.ChargeToRoom,
        then: Yup.string()
          .nullable()
          .required(
            formatMessage({
              defaultMessage: 'Please provide your room number',
              id: 'B32WVH',
            }),
          ),
        otherwise: Yup.string().nullable(),
      }),
      roomName: Yup.string().when('paymentMethod.processorType', {
        is: PaymentProcessorType.ChargeToRoom,
        then: Yup.string()
          .nullable()
          .required(
            formatMessage({
              defaultMessage: 'Please provide the last name on your booking',
              id: 'eZLhwz',
            }),
          ),
        otherwise: Yup.string().nullable(),
      }),
      deliveryOption:
        orderingType &&
        [OrderingType.InVenue, OrderingType.Delivery].includes(orderingType) &&
        hasOptions
          ? Yup.string()
              .nullable()
              .required(
                formatMessage({
                  id: '0xNxPW',
                  defaultMessage: 'Please choose an option',
                }),
              )
          : Yup.string().nullable(),
      pickupOption:
        orderingType &&
        ![OrderingType.InVenue, OrderingType.Delivery].includes(orderingType) &&
        hasOptions
          ? Yup.string()
              .nullable()
              .required(
                formatMessage({
                  id: '0xNxPW',
                  defaultMessage: 'Please choose an option',
                }),
              )
          : Yup.string().nullable(),
      isOver18: paymentFormData?.guestVenue?.ageRestrictionText
        ? Yup.boolean()
            .required(
              formatMessage({
                id: 'Q5SxGZ',
                defaultMessage: 'Confirmation is needed to continue',
              }),
            )
            .oneOf(
              [true],
              formatMessage({
                id: 'Q5SxGZ',
                defaultMessage: 'Confirmation is needed to continue',
              }),
            )
        : Yup.boolean().nullable(),
      marketingUserOptIn: Yup.boolean().nullable(),
      marketingUserOptInVisible: Yup.boolean().nullable(),
      savePaymentDetails: Yup.boolean().nullable(),
      // cart details
      venueSlug: Yup.string().required(
        formatMessage({
          defaultMessage: 'Venue slug is required',
          id: '1NzuOL',
        }),
      ),
      cardToken: Yup.string().nullable(),
      paymentPath: Yup.string().nullable(),
      type: Yup.string()
        .oneOf(Object.values(OrderingType))
        .required(
          formatMessage({
            defaultMessage: 'Ordering type is required',
            id: 'lx5PHW',
          }),
        ),
      reApplyVenueWide: Yup.boolean().default(true).optional(),
      eonxPaymentIntentId: isEonxEnv
        ? Yup.string().required()
        : Yup.string().nullable(),
      eonxPaymentApiKey: isEonxEnv
        ? Yup.string().required()
        : Yup.string().nullable(),
    })
    .concat(paymentMethodSchema)
}

// This doesn't seem to work anymore, we only get paymentMethod from the type. Update Yup or Swap to zod?
/*
  https://github.com/jquense/yup/issues/916
*/
export interface PaymentValues
  extends Yup.InferType<ReturnType<typeof getPaymentSchema>> {
  paymentMethod: PaymentMethodValue | null
}
